<script>
export default {
  name: 'Material',
  page: {
    title: 'Υλικό'
  }
}
</script>

<template>
  <div>
    <div class="page-title">
      <b-container class="bv-example-row">
          <h1 class="title">Υλικό</h1>
      </b-container>
    </div>
    <div class="page-body">
      <b-container class="bv-example-row">
        <p class="page-text">
          Μπορείτε να δείτε και να κατεβάσετε το Εγχειρίδιο Χρήσης <a target="_blank" href="pdf/easy-escape-user-manual.pdf">εδώ</a>
        </p>
        <p class="page-text">
          Μπορείτε να δείτε και να κατεβάσετε το User Manual για την mobile εφαρμογή <a target="_blank" href="pdf/easy-escape-mobile-app-user-manual.pdf">εδώ</a>
        </p>
        <p class="page-text">
          Μπορείτε να δείτε και να κατεβάσετε το Εγχειρίδιο Χρήσης του συστήματος <a target="_blank" href="pdf/easy-escape-system-user-manual.pdf">εδώ</a>
        </p>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use './../assets/scss/abstracts' as *;

.page-title {
  background-color: $white;
  padding: $s-xl 0 $s-s 0;
}

.title {
  color: $black;
  font-family: "Waiting for the Sunrise",cursive;
  padding: $s-s 0;
}

.subtitle {
 color: $red;
}

.page-body {
  background-color: $alto;
  color: $black;
  padding: $s-xl 0;
}

.page-text {
  line-height: $s-xl;
}
</style>
